import { FeatureData } from '@innedit/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import requireBoutique, {
  BoutiqueProps,
} from '../../../../../../../../utils/requireBoutique';
import CMSView from '../../../../../../../CMS/components/View/index';
import List from '../../../../../../../CMS/containers/Boutique/components/new/List';
import params from '../../params.json';
import Item from './Item';

const FeatureList: FC<BoutiqueProps & { itemPathnamePrefix: string }> = ({
  boutique,
  itemPathnamePrefix,
}) => {
  const { t } = useTranslation();

  return (
    <CMSView>
      <List
        item={Item}
        itemPathnamePrefix={itemPathnamePrefix}
        listClassName=""
        model={
          new FeatureData(boutique, {
            params,
            collectionName: 'productFeatures',
          })
        }
        title={t('caracteristiques')}
      />
    </CMSView>
  );
};

export default requireBoutique(FeatureList);
