import { FirebaseFirestore } from '@innedit/innedit';
import classnames from 'classnames';
import objectHash from 'object-hash';
import React, { FC, SyntheticEvent, useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

import Button from '../../../../../../components/Button';
import IconEdit from '../../../../../../icons/Edit';
import { ListItemActionProps } from './props';
import ItemSC from './styles';

const ItemTypes = {
  ITEM: 'item',
};

interface DragProduit {
  index: number;
  type: string;
}

const Item: FC<{
  actions?: ListItemActionProps | ListItemActionProps[];
  className?: string;
  contentClassName?: string;
  displayActionId?: boolean;
  doc: FirebaseFirestore.DocumentSnapshot;
  handleChangePosition?: (oldIndex: number, newIndex: number) => void;
  index: number;
  onClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}> = ({
  actions,
  children,
  className,
  contentClassName,
  displayActionId = true,
  doc,
  handleChangePosition,
  index,
  onClick,
}) => {
  const ref = useRef<HTMLLIElement>(null);

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.ITEM,
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
    }),
    drop(item: DragProduit, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      if (handleChangePosition) {
        handleChangePosition(dragIndex, hoverIndex);
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    item: { index, type: ItemTypes.ITEM },
  });

  if (handleChangePosition) {
    console.info('handleChangePosition');
    drag(drop(ref));
  }

  // 'flex border p-1.5 justify-between',
  return (
    <ItemSC
      ref={ref}
      className={classnames(className, {
        'border-green-500': isOver,
        'border-red-500': isDragging,
      })}
    >
      <div className={contentClassName}>{children}</div>
      <div className="flex space-x-1 ml-3 self-start">
        {actions &&
          (!Array.isArray(actions) ? [actions] : actions).map(
            ({
              className: actionClassName,
              tooltip,
              label,
              onClick: actionOnClick,
              type,
              ...others
            }) => (
              <Button
                key={objectHash({ className, label, tooltip, type })}
                className={actionClassName}
                onClick={actionOnClick}
                status="secondary"
                text={label}
                tooltip={tooltip}
                type={type}
                {...others}
              />
            ),
          )}
        {doc.id && displayActionId && (
          <Button status="secondary" tooltip={doc.id} type="tooltip" />
        )}
        <Button data-index={index} iconLeft={IconEdit} onClick={onClick} />
      </div>
    </ItemSC>
  );
};

export default Item;
