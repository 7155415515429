import styled from 'styled-components';

const ItemSC = styled.li`
  display: flex;
  border: 1px solid #e4e7ed;
  padding: 0.375rem;
  background: #fbfbfc;
  margin-bottom: 0.375rem;
  justify-content: space-between;

  .block {
    display: block;
    font-size: 80%;
  }

  .field-actions {
    margin: 0;
  }
`;

export default ItemSC;
