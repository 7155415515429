import { FeatureData } from '@innedit/innedit';
import React, { FC, SyntheticEvent } from 'react';

import requireBoutique, {
  BoutiqueProps,
} from '../../../../../../../../utils/requireBoutique';
import CMSView from '../../../../../../../CMS/components/View/index';
import Form from '../../../../../../../CMS/containers/Boutique/components/new/Form';
import params from '../../params.json';

const FeatureForm: FC<
  BoutiqueProps & { docId: string; itemPathnamePrefix: string }
> = ({ boutique, docId, itemPathnamePrefix, user }) => {
  const handlePreviousOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // TODO gérer cette fonction
  };

  const handleNextOnClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // TODO gérer cette fonction
  };

  return (
    <CMSView>
      <Form
        docId={docId}
        itemPathnamePrefix={itemPathnamePrefix}
        model={
          new FeatureData(boutique, {
            params,
            collectionName: 'productFeatures',
          })
        }
        name="caracteristique"
        nextOnClick={handleNextOnClick}
        previousOnClick={handlePreviousOnClick}
        user={user}
      />
    </CMSView>
  );
};

export default requireBoutique(FeatureForm);
