/* eslint-disable max-len */
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CaracteristiqueForm from '../../core/sysext/Boutique/containers/Produit/containers/Caracteristique/components/Form';
import CaracteristiqueList from '../../core/sysext/Boutique/containers/Produit/containers/Caracteristique/components/List';
import Backoffice from '../../core/sysext/CMS/containers/Backoffice';

const CaracteristiquesPage: FC<PageProps> = props => {
  const {
    location,
    params: { '*': star },
  } = props;

  return (
    <Backoffice location={location}>
      {star ? (
        <CaracteristiqueForm
          docId={star}
          itemPathnamePrefix="/boutique/caracteristiques/"
          {...props}
        />
      ) : (
        <CaracteristiqueList
          {...props}
          itemPathnamePrefix="/boutique/caracteristiques/"
        />
      )}
    </Backoffice>
  );
};

export default CaracteristiquesPage;
