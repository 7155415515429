import { useSelector } from 'dataformjs';
import React, { FC } from 'react';

export interface BoutiqueProps {
  boutique: firebase.default.firestore.DocumentSnapshot;
  user: firebase.default.firestore.DocumentSnapshot;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const requireBoutique =
  <P extends Record<string, unknown>>(
    ComposedComponent: FC<P & BoutiqueProps>,
    // eslint-disable-next-line react/display-name
  ): FC<P> =>
  props => {
    const { boutiques, indexBoutique, user } = useSelector(
      (globalState: any) => globalState.cms,
    );

    const boutique =
      boutiques && boutiques.length > 0 ? boutiques[indexBoutique] : undefined;

    if (!boutique) {
      return <div>Chargement en cours de la boutique</div>;
    }

    return (
      <ComposedComponent {...(props as P)} boutique={boutique} user={user} />
    );
  };

export default requireBoutique;
